import React from 'react'
import styled from 'styled-components'
import { Text } from '@junglescout/edna'

import { t } from 'services/i18n'
import { MARKETPLACES } from 'constants/countries'
import { sizes } from 'ui_elements/TableV2/styles'
import { TrendCell } from 'components/table_cells/TrendCell'
import { DistributionPositionCell } from 'components/competitive_intelligence/keyword_analysis_tab/PositionTrackerTableHeader/DistributionPositionCell'

const StyledText = styled(Text)`
  border-radius: 50%;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.orange100 : theme.colors.grey075};
`

export const CI_MARKETS_LOCAL_STORAGE_KEY = 'competitiveIntelligence' // Store settings per market (object of market ids and their settings)
export const CI_GLOBAL_LOCAL_SOTRAGE_KEY = 'competitiveIntelligenceGlobal' // Store settings for CI feature globally

export const CI_SUPPORTED_MARKETPLACES = [MARKETPLACES.us]

export const CI_POSITION_TRACKING_HEATMAP_BOOL_KEY = 'ciPositionTrackingHeatmap'

export const MAX_ADD_ASINS_ALLOWED = 100

export const MAX_PINNED_PRODUCTS = 6

export const PRODUCTS_PAGE_SIZE = 25

export const MARKETS_PAGE_SIZE = 50

export const MIN_EXTENSION_VERSION = 866 // v8.6.6 (Includes the review breakdown, released on Aug 27th 2024)

export const SUPPORTED_CATEGORY_KB_ARTICLE_LINK =
  'https://support.junglescout.com/hc/en-us/articles/25105595832727-Supported-and-Unsupported-Categories'

/**
 * The keys of this object should be determined by the number of pinned products.
 * Pinned products = Orange Hues
 * Unpinned products = Grey Hues
 */
export const GRAPH_COLOR_MAP = {
  0: ['grey800', 'grey700', 'grey600', 'grey500', 'grey400', 'grey300'],
  1: ['orange500', 'grey700', 'grey600', 'grey500', 'grey400', 'grey300'],
  2: ['orange700', 'orange500', 'grey600', 'grey500', 'grey400', 'grey300'],
  3: ['orange700', 'orange500', 'orange300', 'grey500', 'grey400', 'grey300'],
  4: ['orange700', 'orange500', 'orange400', 'orange300', 'grey400', 'grey300'],
  5: [
    'orange700',
    'orange600',
    'orange500',
    'orange400',
    'orange300',
    'grey300'
  ],
  6: [
    'orange900',
    'orange700',
    'orange600',
    'orange500',
    'orange400',
    'orange300'
  ]
}

export const MANUAL_SELECT = 'manual'
export const PRODUCTS_SELECT = 'products'
export const PRODUCT_SELECT_TYPES = [
  {
    content: t(
      'competitive_intelligence:CreateSegmentModal.selectType.manual',
      'Enter Manually'
    ),
    value: MANUAL_SELECT
  },
  {
    content: t(
      'competitive_intelligence:CreateSegmentModal.selectType.products',
      'Your Products'
    ),
    value: PRODUCTS_SELECT
  }
]

export const KEYWORD_ANALYSIS_HEATMAP_BOOL_KEY = 'keyword-analysis-heatmap'

export const SELECTOR_TOOLTIP_TYPES = {
  primary_product: t(
    'competitive_intelligence:ProductsSelector.selected.primaryProduct',
    'Product selected as the primary product'
  ),
  max_products: t(
    'competitive_intelligence:ProductsSelector.selected.maxProducts',
    'Max number of competitors selected'
  )
}

export const CHART_TOOLTIP_CONFIG = {
  width: 240,
  offset: 12, // space between tooltip and the cursor
  dotSize: 12
}

export const DATE_RANGE_OPTIONS = [
  {
    value: '1_months',
    content: t('generic:DateRangeOptions.1m', '1M')
  },
  {
    value: '3_months',
    content: t('generic:DateRangeOptions.3m', '3M')
  },
  {
    value: '6_months',
    content: t('generic:DateRangeOptions.6m', '6M')
  },
  {
    value: '12_months',
    content: t('generic:DateRangeOptions.12m', '12M')
  }
]

export const PRODUCT_SLIDEOUT_ATTRIBUTES = [
  'revenue',
  'units_sold',
  'avg_price',
  'star_rating',
  'reviews'
]

export const PRODUCT_SLIDEOUT_GRAPH_COLORS = {
  revenue: 'graphOrange',
  units_sold: 'graphBlue',
  avg_price: 'graphPurple',
  star_rating: 'graphRed',
  reviews: 'graphTeal'
}

export const PRODUCT_ACTIONS_KEYS = {
  productDetails: 'productDetails',
  viewKeywords: 'viewKeywords',
  analyzeReviews: 'analyzeReviews',
  viewOnAmazon: 'viewOnAmazon',
  copyAsin: 'copyAsin'
}

export const KEYWORD_RANK_DISTRIBUTION_TOOLTIPS = {
  position: t(
    'constants:KEYWORD_RANK_DISTRIBUTION_TOOLTIPS.position',
    'The position that the keyword ranks within on the Amazon search results page.'
  ),
  totalKeyword: t(
    'constants:KEYWORD_RANK_DISTRIBUTION_TOOLTIPS.totalKeyword',
    'The total number of keywords the product ranks for.'
  ),
  totalNetChange: t(
    'constants:KEYWORD_RANK_DISTRIBUTION_TOOLTIPS.totalNetChange',
    'The total net change of keywords lost and gained over the past 7 days.'
  ),
  gainedKeyword: t(
    'constants:KEYWORD_RANK_DISTRIBUTION_TOOLTIPS.gainedKeyword',
    'The number of keywords that moved from a lower ranked position to a higher ranked position.'
  ),
  lostKeyword: t(
    'constants:KEYWORD_RANK_DISTRIBUTION_TOOLTIPS.lostKeyword',
    'The number of keywords that moved from a higher ranked position to a lower ranked position.'
  ),
  top5: t(
    'constants:KEYWORD_RANK_DISTRIBUTION_TOOLTIPS.top5',
    'Keywords ranking in the 1-5 positions on the Amazon search results page'
  ),
  top10: t(
    'constants:KEYWORD_RANK_DISTRIBUTION_TOOLTIPS.top10',
    'Keywords ranking in the 1-10 positions on the Amazon search results page'
  ),
  top50: t(
    'constants:KEYWORD_RANK_DISTRIBUTION_TOOLTIPS.top50',
    'Keywords ranking in the 1-50 positions on the Amazon search results page'
  )
}

export const RANK_DISTRIBUTION_COLUMNS = (selectedRange, filter) => {
  const { label } = selectedRange
  return [
    {
      index: 0,
      position: 0,
      accessor: 'name',
      Header: t(
        'rank_tracker:KeywordRankDistributionTable.labels.position',
        'Position'
      ),
      width: 270,
      headerTooltip: KEYWORD_RANK_DISTRIBUTION_TOOLTIPS.position,
      Cell: ({ row }) => (
        <DistributionPositionCell filter={filter} label={label} row={row} />
      ),
      defaultSelection: true
    },
    {
      index: 1,
      position: 1,
      accessor: 'totalKeyword',
      Header: t(
        'rank_tracker:KeywordRankDistributionTable.labels.totalKeyword',
        'Total Keyword'
      ),
      headerTooltip: KEYWORD_RANK_DISTRIBUTION_TOOLTIPS.totalKeyword,
      subHeader: t(
        'rank_tracker:KeywordRankDistributionTable.labels.yesterday',
        'Yesterday'
      ),
      sortable: false,
      width: sizes.large,
      defaultSelection: true,
      type: 'number'
    },
    {
      index: 2,
      position: 2,
      accessor: 'totalNetChange',
      Header: t(
        'rank_tracker:KeywordRankDistributionTable.labels.totalNetChange',
        'Total Net Change'
      ),
      headerTooltip: KEYWORD_RANK_DISTRIBUTION_TOOLTIPS.totalNetChange,
      subHeader: t(
        'rank_tracker:KeywordRankDistributionTable.labels.change',
        '{{day}} Change',
        { day: label }
      ),
      width: sizes.large,
      defaultSelection: true,
      sortable: false,
      Cell: data =>
        TrendCell({ ...data, skipPercentage: true, showZero: true }),
      type: 'number'
    },
    {
      index: 3,
      position: 3,
      accessor: 'totalGainedChange',
      Header: t(
        'rank_tracker:KeywordRankDistributionTable.labels.totalGainedChange',
        'Gained Keywords'
      ),
      headerTooltip: KEYWORD_RANK_DISTRIBUTION_TOOLTIPS.gainedKeyword,
      subHeader: t(
        'rank_tracker:KeywordRankDistributionTable.labels.change',
        '{{day}} Change',
        { day: label }
      ),
      defaultSelection: true,
      sortable: false,
      width: sizes.large,
      Cell: data =>
        TrendCell({ ...data, skipPercentage: true, showZero: true }),
      type: 'number'
    },
    {
      index: 4,
      position: 4,
      accessor: 'totalLostChange',
      Header: t(
        'rank_tracker:KeywordRankDistributionTable.labels.lostKeyword',
        'Lost Keywords'
      ),
      headerTooltip: KEYWORD_RANK_DISTRIBUTION_TOOLTIPS.lostKeyword,
      subHeader: t(
        'rank_tracker:KeywordRankDistributionTable.labels.change',
        '{{day}} Change',
        { day: label }
      ),
      sortable: false,
      type: 'number',
      width: sizes.large,
      Cell: data =>
        TrendCell({ ...data, skipPercentage: true, showZero: true }),
      defaultSelection: true
    }
  ]
}

export const RANK_DISTRIBUTION_DAY_TOGGLE = [
  {
    content: '7D',
    value: '7D'
  },
  {
    content: '30D',
    value: '30D'
  }
]

export const RANK_TRACKER_DISTRIBUTION_POPOVER_FILTERS = ({
  gained,
  lost,
  selected
}) => [
  {
    content: (
      <span>
        {t(
          'competitive_intelligence:KeywordAnalysis.KeywordDistribution.gained',
          'Gained'
        )}{' '}
        <StyledText
          variant="bodyXs"
          padding="0 4px"
          color="inherit"
          selected={selected === 'gained'}>
          {gained}
        </StyledText>
      </span>
    ),
    value: 'gained'
  },
  {
    content: (
      <span>
        {t(
          'competitive_intelligence:KeywordAnalysis.KeywordDistribution.lost',
          'Lost'
        )}{' '}
        <StyledText
          variant="bodyXs"
          padding="0 4px"
          color="inherit"
          selected={selected === 'lost'}>
          {Math.abs(lost)}
        </StyledText>
      </span>
    ),
    value: 'lost'
  }
]

export const CI_INCENTIVE_UPGRADE_LINK = {
  suite:
    'https://members.junglescout.com/#/registrations?mt=79ae6ed35e7a3c776933024ea9623236&lang=en-US',
  pro:
    'https://members.junglescout.com/#/registrations?mt=7404e5d1ff32cb046215ebcae5429137&lang=en-US'
}

export const CI_DEMO_SEGMENT_LOCAL_STORAGE_KEY = 'CI-Triggered-Demo-Segment'
