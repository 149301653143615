import { isMobile } from 'react-device-detect'

export const CHAT_SPACING = isMobile ? 6 : 12
export const AVATAR_SIZE = isMobile ? 28 : 32

export const SELLER_FEATURES_ENDPOINTS = [
  {
    id: 'get_units_sold',
    path: 'api/junglekit/profit_overview/units_sold'
  },
  {
    id: 'get_revenue',
    path: 'api/junglekit/profit_overview/revenue'
  },
  {
    id: 'get_net_profit',
    path: 'api/junglekit/profit_overview/net_profit'
  },
  {
    id: 'get_costs',
    path: 'api/junglekit/profit_overview/cost'
  },
  {
    id: 'get_profit_overview_ppc',
    path: 'api/junglekit/profit_overview/ppc'
  },
  {
    id: 'get_ad_analytics_overview',
    path: 'api/junglekit/ad_analytics/overview'
  }
]

export const RESEARCH_FEATURES_ENDPOINTS = [
  {
    id: 'get_keyword_data',
    path: 'api/keyword_engine/get_related_keywords',
    defaultParams: {
      country: 'us',
      column: 'estimatedExactSearchVolume',
      page_size: 3,
      from: 0,
      skipCounter: true,
      all_variants: false,
      include_keyword_lists: false
    }
  }
]

export const CHATBOT_ENDPOINTS = [
  ...SELLER_FEATURES_ENDPOINTS,
  ...RESEARCH_FEATURES_ENDPOINTS
]
