import React from 'react'
import { Translation } from 'react-i18next'
import { addMonthsToDate, addYearsToDate } from 'helpers/date'

export const BASE_PLAN_BILLING_FREQUENCY_OPTIONS = [
  {
    name: 'Annual',
    translated_name: (
      <Translation ns="constants">
        {t => t('constants:BILLING_FREQUENCY_OPTIONS.yearly.name', 'Annual')}
      </Translation>
    ),
    value: 'yearly',
    short: 'Year',
    plural: 'Years',
    expirationFormatter: addYearsToDate
  },
  {
    name: 'Monthly',
    translated_name: (
      <Translation ns="constants">
        {t => t('constants:BILLING_FREQUENCY_OPTIONS.monthly.name', 'Monthly')}
      </Translation>
    ),
    value: 'monthly',
    short: 'Month',
    plural: 'Months',
    expirationFormatter: addMonthsToDate
  }
]
