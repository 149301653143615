export const BLOCKED_REQUEST_TYPES = {
  REQUESTS_UPSELL: 'requestsUpsell',
  ENDPOINT_NOT_FOUND: 'endpointNotFound',
  NO_SELLER_ACCOUNT: 'noSellerAccount',
  SELLER_ACCOUNT_SYNCING: 'sellerAccountSyncing'
}

export const getBlockedRequestAnswers = (blockedRequestType, name, t) => {
  switch (blockedRequestType) {
    case BLOCKED_REQUEST_TYPES.REQUESTS_UPSELL:
      return t(
        'chatbot:BlockedRequests.requestsUpsell.answer',
        "Hey {{name}}, \n\n&nbsp;\n\n Great question - we'd love to dive deep and provide you with tailored insights! To do that, we recommend upgrading to our Growth Accelerator or Brand Owner plan, unlocking all the advanced AI Assist features. \n\n&nbsp;\n\n Advanced AI Assist analyzes your business data for valuable insights tailored to your needs.\n\nOptimize operations, enhance decision-making, and maximize productivity to gain a competitive edge.",
        { name }
      )
    case BLOCKED_REQUEST_TYPES.ENDPOINT_NOT_FOUND:
      return t(
        'chatbot:BlockedRequests.endpointNotFound.answer',
        "Sorry, I can't answer that question now. Try asking me something else."
      )
    case BLOCKED_REQUEST_TYPES.NO_SELLER_ACCOUNT:
      return t(
        'chatbot:BlockedRequests.noSellerAccount.answer',
        "Connect your Amazon seller account to Jungle Scout to unlock key data about your business. Once you do, we'll be able to show you your sales, calculate your true profits, and answer questions about your business."
      )
    case BLOCKED_REQUEST_TYPES.SELLER_ACCOUNT_SYNCING:
      return t(
        'chatbot:BlockedRequests.sellerAccountSyncing.answer',
        "Hey {{name}}, \n\n&nbsp;\n\n We're in the process of syncing your Amazon Seller Central Account. Hang tight - it might take up to 24 hours for all your data to load. Once it's in, we'll be ready to tackle any questions you throw our way! 🚀",
        { name }
      )
    default:
      return undefined
  }
}
